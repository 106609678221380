import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Link, StaticQuery, graphql } from 'gatsby'

import { Navigation } from '.'

// Styles
import '../../styles/app.css'

import { DoubleArrow } from "@styled-icons/material-rounded/DoubleArrow"

const siteSettings = {
    title: "Open Racer",
    lang: "en",
    codeinjection_styles: "" // Add any custom styles here if needed
}

/**
* Main layout component
*
* The Layout component wraps around each page and template.
* It also provides the header, footer as well as the main
* styles, and meta data for each page.
*
*/
const DefaultLayout = ({ data, children, bodyClass, showLocation, image, title }) => {
    const site = siteSettings

    return (
        <>
            <Helmet>
                <html lang={site.lang} />
                <style type="text/css">{`${site.codeinjection_styles}`}</style>
                <body className={bodyClass} />
                <meta
                    name="git-commit"
                    content={data.gitCommit?.hash?.substring(0, 8)}
                />
            </Helmet>

            <div className="site-wrapper">
                {/* The main header section on top of the screen */}
                <Navigation />

                <main className="site-main outer">
                    <div className="inner">
                        

                        {title && (
                            <header className="post-full-header">
                                <h1 className="post-full-title">{title}</h1>
                            </header>
                        )}

                        {image && (
                            <figure
                                className="post-full-image"
                                style={{
                                    backgroundImage: `url(${image})`,
                                }}
                            />
                        )}
                        {children}
                    </div>
                </main>

                <footer className="site-footer outer">
                    <div className="site-footer-content inner">
                        <nav className="site-footer-nav">
                            <ul className="flex flex-col md:flex-row">
                                <li>
                                    <a href="/motorsport-countries">
                                        Popular Cities
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://moodboardai.com"
                                        target="_blank"
                                    >
                                        Mood Board AI
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://myroomdesigner.ai/"
                                        target="_blank"
                                    >
                                        AI Room Designer
                                    </a>
                                </li>
                            </ul>
                        </nav>
                        <section className="copyright">
                            <a href="/">{site.title}</a> &copy;{" "}
                            {new Date().getFullYear()}
                        </section>
                    </div>
                </footer>
            </div>
        </>
    )
}

DefaultLayout.propTypes = {
    children: PropTypes.node.isRequired,
    bodyClass: PropTypes.string,
    isHome: PropTypes.bool,
    data: PropTypes.shape({
        file: PropTypes.object,
    }).isRequired,
}

const DefaultLayoutSettingsQuery = props => (
    <StaticQuery
        query={graphql`
            query GhostSettings {
                file(relativePath: {eq: "ghost-icon.png"}) {
                    childImageSharp {
                        fixed(width: 30, height: 30) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
                gitCommit(latest: { eq: true }) {
                    hash
                }
            }
        `}
        render={data => <DefaultLayout data={data} {...props} />}
    />
)

export default DefaultLayoutSettingsQuery
